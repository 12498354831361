import React from "react";
import axios from "axios";
import Select from "react-select";
import "./App.css";

class SyncById extends React.Component {
  constructor() {
    super();
    this.state = {
      response: [],
      url: "https://eprelclient.i-ways-network.org/api/ledvance/product-details?modelIdentifier=",
      token: "Basic ZXByZWwtaXdheXMtdXNlcjplcHJlbC1pd2F5cy1wYXNzd29yZDEyMw==",
      getProductsURL: "https://eprelclient.i-ways-network.org/api/getVariants",
      productURL:
        "https://eprelclient.i-ways-network.org/api/getProducdsMetaFields/",
      token:
        "Basic NjQ2ZmRlOTI0ZTA5NDJkMmNiYzE0ZWZhMDlhYzI5NTE6c2hwcGFfM2RiM2RlODVlYjAzOWExZmExZDNiNTE0NjVjMTI1OGQ=",
      modelIdentifierURL:
        "https://eprelclient.i-ways-network.org/api/ledvance/product-details?modelIdentifier=",
      putURL: "https://eprelclient.i-ways-network.org/api/getMetaFields",
      modelIdentifier: "",
      modelIdentifierObj: "",
      productsVariants: [],
      metaFields: null,
      modelIdentiferData: null,
      isLoading: false,
      isFinished: false,
    };
  }
  getLEDVanceData = (event) => {
    event.preventDefault();

    axios
      .get(this.state.url + this.state.modelIdentifier, {
        headers: {
          Authorization: this.state.token,
        },
      })
      .then((res) => {
        this.setState({
          response: res.data,
        });
      });
  };

  urlChangeHandler = (e) => {
    this.setState({
      url: e.target.value,
    });
  };

  tokenChangeHandler = (e) => {
    this.setState({
      token: e.target.value,
    });
  };

  modelChangeHandler = (e) => {
    this.setState({
      modelIdentifier: e.value,
      modelIdentifierObj: e,
    });
  };

  componentDidMount = async () => {
    await axios
      .get(this.state.getProductsURL + "?countryCode=" + this.props.language, {
        auth: {
          username: "eprel-iways-user",
          password: "eprel-iways-password123",
        },
      })
      .then((res) => {
        if (res.data) {
          let updatedResData = [];
          for (var i = 0; i < res.data.length; i++) {
            updatedResData = [...updatedResData, ...res.data[i].variants];
          }
          this.setState({
            response: updatedResData ? [...updatedResData] : [],
          });
        }
      });

    const updatedData = this.state.response?.map((item) => {
      return {
        value: item.id,
        label: item.sku,
      };
    });

    this.setState({
      productsVariants: [...updatedData],
    });
  };

  getProductById = async (event) => {
    event.preventDefault();

    this.setState({
      isLoading: true,
    });
    await axios
      .get(
        this.state.productURL +
          this.state.modelIdentifier +
          "?countryCode=" +
          this.props.language,
        {
          auth: {
            username: "eprel-iways-user",
            password: "eprel-iways-password123",
          },
        }
      )
      .then((res) => {
        this.setState({
          metaFields: res.data,
        });
      });

    const metaFieldsData = this.state.metaFields?.metafields;
    if (metaFieldsData) {
      let isValid = false;
      metaFieldsData?.forEach((item) => {
        if (
          item.key.trim().toLowerCase() == "modelidentifier" &&
          item.value !== "default"
        ) {
          if (item.value) {
            this.getModelIndetifierData(item.value, metaFieldsData);
            isValid = true;
          }
        }
      });

      if (!isValid) {
        this.setState({
          isLoading: false,
        });
        return;
      }
    }
  };

  updateFieldsData = (data) => {
    data?.forEach((item) => {
      if (item.key === "bigColorThumb") {
        this.updateBigColor(item.id);
      }
      if (item.key === "energyClassImage") {
        this.updateEnergyClass(item.id);
      }
      if (item.key === "eprelRegistrationNumber") {
        this.updateMetaRegistration(item.id);
      }
      if (item.key === "energyclassletter") {
        this.updateEnergyClassLetter(item.id);
      }
      if (item.key === "EprelDataSheetDE") {
        this.updateDataSheet(item.id, "EprelDataSheetDE");
      }
      if (item.key === "EprelDataSheetFR") {
        this.updateDataSheet(item.id, "EprelDataSheetFR");
      }
      if (item.key === "EprelDataSheetIT") {
        this.updateDataSheet(item.id, "EprelDataSheetIT");
      }
    });

    this.setState({
      isLoading: false,
      isFinished: true,
    });
  };

  getModelIndetifierData = async (value, metaFieldsData) => {
    await axios
      .get(
        this.state.modelIdentifierURL +
          value +
          "&countryCode=" +
          this.props.language,
        {
          auth: {
            username: "eprel-iways-user",
            password: "eprel-iways-password123",
          },
        }
      )
      .then((res) => {
        this.setState({
          modelIdentiferData: res.data,
        });
        this.updateFieldsData(metaFieldsData);
      });
  };

  updateBigColor = async (id) => {
    const data = this.state.modelIdentiferData;

    let bigColorThumb = {
      metafield: {
        id: id,
        value: data?.data?.bigColorThumb,
        key: "bigColorThumb",
      },
    };
    await axios
      .put(
        this.state.putURL +
          "?metafieldId=" +
          id +
          "&countryCode=" +
          this.props.language,
        bigColorThumb,
        {
          auth: {
            username: "eprel-iways-user",
            password: "eprel-iways-password123",
          },
        }
      )
      .then((res) => {});
  };

  updateEnergyClass = async (id) => {
    const data = this.state.modelIdentiferData;

    let energyClassImage = {
      metafield: {
        id: id,
        value: data?.data?.energyClassImage,
        key: "energyClassImage",
      },
    };

    await axios
      .put(
        this.state.putURL +
          "?metafieldId=" +
          id +
          "&countryCode=" +
          this.props.language,
        energyClassImage,
        {
          auth: {
            username: "eprel-iways-user",
            password: "eprel-iways-password123",
          },
        }
      )
      .then((res) => {});
  };

  updateMetaRegistration = async (id) => {
    const data = this.state.modelIdentiferData;

    // registration number
    let eprelRegistrationNumber = {
      metafield: {
        id: id,
        value: data?.data?.eprelRegistrationNumber,
        key: "eprelRegistrationNumber",
      },
    };
    await axios
      .put(
        this.state.putURL +
          "?metafieldId=" +
          id +
          "&countryCode=" +
          this.props.language,
        eprelRegistrationNumber,
        {
          auth: {
            username: "eprel-iways-user",
            password: "eprel-iways-password123",
          },
        }
      )
      .then((res) => {});

    this.setState({
      isLoading: false,
    });
  };

  updateEnergyClassLetter = async (id) => {
    const data = this.state.modelIdentiferData;

    // energyclassletter
    let energyclassletter = {
      metafield: {
        id: id,
        value: data?.data?.energyClass,
        key: "energyclassletter",
      },
    };
    await axios
      .put(
        this.state.putURL +
          "?metafieldId=" +
          id +
          "&countryCode=" +
          this.props.language,
        energyclassletter,
        {
          auth: {
            username: "eprel-iways-user",
            password: "eprel-iways-password123",
          },
        }
      )
      .then((res) => {});
  };

  updateDataSheet = async (id, keyID) => {
    const data = this.state.modelIdentiferData;

    // EprelDataSheetDE
    let EprelDataSheetDE = {
      metafield: {
        id: id,
        value: data?.data?.productDataSheet,
        key: keyID,
      },
    };
    await axios
      .put(
        this.state.putURL +
          "?metafieldId=" +
          id +
          "&countryCode=" +
          this.props.language,
        EprelDataSheetDE,
        {
          auth: {
            username: "eprel-iways-user",
            password: "eprel-iways-password123",
          },
        }
      )
      .then((res) => {});

    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { modelIdentiferData, response, url, token } = this.state;

    return (
      <div className="syncByIdWrapper">
        <div className="formWrapper">
          <div className="col-md-6 offset-md-3">
            <button
              onClick={() => this.props.viewModeHanler(true)}
              className="backLink"
            >
              Go Back
            </button>
            <h3>Get repsonse back from EPERL</h3>
            <br />
            <div className="row">
              <form onSubmit={this.getProductById}>
                <div className="col-sm-12 mb-3">
                  <h3 className="fieldLabel">
                    Product Variant(s) {this.state.response.length}
                  </h3>
                  {this.state.productsVariants.length > 0 ? (
                    <>
                      <Select
                        name="skus"
                        value={this.state.modelIdentifierObj}
                        options={[...this.state.productsVariants]}
                        onChange={(e) => this.modelChangeHandler(e)}
                      />
                      {/* <select onChange={(e) => this.modelChangeHandler(e)}>
                        <option></option>
                        {this.state.productsVariants.map((item, index) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </select> */}
                    </>
                  ) : (
                    "Loading SKUs..."
                  )}
                </div>
                <div className="col-md-12 mt-2">
                  {this.state.modelIdentifier && (
                    <input type="submit" value="submit" />
                  )}
                </div>
              </form>
            </div>

            {this.state.modelIdentiferData ? (
              <>
                <div className="attributeWrapper mt-5">
                  <div className="box">
                    <span>Eprel RegistrationNumber</span>
                    <input
                      type="text"
                      value={modelIdentiferData?.data?.eprelRegistrationNumber}
                      disabled={true}
                    />
                  </div>

                  <div className="box">
                    <span>Model Identifier</span>
                    <input
                      type="text"
                      value={modelIdentiferData?.data?.modelIdentifier}
                      disabled={true}
                    />
                  </div>

                  <div className="box">
                    <span>Big color thumb</span>
                    <input
                      type="text"
                      value={modelIdentiferData?.data?.bigColorThumb}
                      disabled={true}
                    />
                  </div>

                  <div className="box">
                    <span>Small Black And White</span>
                    <input
                      type="text"
                      value={modelIdentiferData?.data?.smallBlackAndWhite}
                      disabled={true}
                    />
                  </div>

                  <div className="box">
                    <span>energyClassImage</span>
                    <input
                      type="text"
                      value={modelIdentiferData?.data?.energyClassImage}
                      disabled={true}
                    />
                  </div>

                  <div className="box">
                    <span>energyClass</span>
                    <input
                      type="text"
                      value={modelIdentiferData?.data?.energyClass}
                      disabled={true}
                    />
                  </div>
                  <div className="box">
                    <span>Date Sheet</span>
                    <input
                      type="text"
                      value={modelIdentiferData?.data?.productDataSheet}
                      disabled={true}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        {this.state.isLoading ? "Updating Product..." : ""}
      </div>
    );
  }
}
export default SyncById;
