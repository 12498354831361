import React from "react";
import "./styles.css";
import LogoImg from "../../images/ledvance-logo.png";

const Header = (props) => {
  return (
    <div className="headerWrapper">
      <img className="logo" src={LogoImg} alt="logo" />
      {props.language ? (
        <div className="languageSelect">
          <label for="languages">Choose Store:</label>
          <select onChange={props.setSelectedLanguage} name="languages" id="languages">
            <option value="DE" selected={props.language === "DE"}>DE</option>
            <option value="FR" selected={props.language === "FR"}>FR</option>
            <option value="IT" selected={props.language === "IT"}>IT</option>
          </select>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Header;
