import React, { useState } from "react";
import Header from "./common/Header/index";
import Footer from "./common/Footer/index";
import "./App.css";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const submitHandler = () => {
    if (username === "admin" && password === "admin@ledvance") {
      props.setIsLogin();
    } else {
      alert("Invalid username or password");
    }
  };
  return (
    <>
      <Header />
      <div className="loginFormWrapper">
        <h1>Log In</h1>
        <input
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          type="text"
          placeholder="username"
        />
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder="password"
        />
        <button onClick={submitHandler}>Log In</button>
      </div>
      <Footer />
    </>
  );
};

export default Login;
