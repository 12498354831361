import React from "react"
import "./styles.css"

const Footer = () => {
    return (
        <div className="footerWrapper">
            &copy; 2021, LEDVANCE GmbH. All rights reserved.
        </div>
    )
}

export default Footer