import React from "react";
import axios from "axios";
import SyncById from "./SyncById";
import "./App.css";
import Header from "./common/Header/index";
import Footer from "./common/Footer/index";
import Login from "./login";
import { verifyModelIdentifier } from "./services/ModelIdentifier";
class IwaysEperlGetApi extends React.Component {
  constructor() {
    super();
    this.state = {
      response: null,
      getProductsURL: "https://eprelclient.i-ways-network.org/api/getVariants",
      productURL:
        "https://eprelclient.i-ways-network.org/api/getProducdsMetaFields/",
      token:
        "Basic NjQ2ZmRlOTI0ZTA5NDJkMmNiYzE0ZWZhMDlhYzI5NTE6c2hwcGFfM2RiM2RlODVlYjAzOWExZmExZDNiNTE0NjVjMTI1OGQ=",
      modelIdentifierURL:
        "https://eprelclient.i-ways-network.org/api/ledvance/product-details?modelIdentifier=",
      putURL: "https://eprelclient.i-ways-network.org/api/getMetaFields",
      isSyncMode: true,
      isLoading: false,
      metaFields: null,
      modelIdentiferData: null,
      isFinished: false,
      isLogin: false,
      language: "DE",
    };
  }

  setSelectedLanguage = (event) => {
    this.setState({
      language: event.target.value,
    });
  };

  syncAllData = async (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
    });
    await axios
      .get(this.state.getProductsURL + "?countryCode=" + this.state.language, {
        auth: {
          username: "eprel-iways-user",
          password: "eprel-iways-password123",
        },
      })
      .then((res) => {
        if (res.data) {
          let updatedResData = [];
          for (var i = 0; i < res.data.length; i++) {
            updatedResData = [...updatedResData, ...res.data[i].variants];
          }
          this.setState({
            response: updatedResData ? [...updatedResData] : [],
          });
        }
      });

    for (let loop = 0; loop < this.state.response.length; loop++) {
      if (this.state.response[loop].id) {
        await new Promise((r) => setTimeout(r, 6000));
        this.getProductById(this.state.response[loop].id);
      }
    }
  };

  setIsLogin = () => {
    this.setState({
      isLogin: true,
    });
  };

  sleep = (ms) => new Promise((res) => setTimeout(res, ms));

  getProductById = async (id) => {
    await axios
      .get(this.state.productURL + id + "?countryCode=" + this.state.language, {
        auth: {
          username: "eprel-iways-user",
          password: "eprel-iways-password123",
        },
      })
      .then((res) => {
        this.setState({
          metaFields: res.data,
        });
      });

    const metaFieldsData = this.state.metaFields?.metafields;
    this.setState({
      modelIdentiferData: {},
    });

    if (metaFieldsData) {
      let isValid = false;
      for (let loop = 0; loop < metaFieldsData.length; loop++) {
        if (
          metaFieldsData[loop].key.trim().toLowerCase() === "modelidentifier" &&
          metaFieldsData[loop].value !== "default"
        ) {
          if (metaFieldsData[loop].value) {
            await this.sleep(8000);
            await this.getModelIndetifierData(
              metaFieldsData[loop].value,
              metaFieldsData
            );
            isValid = true;
          }
        }
      }

      if (!isValid) {
        return;
      }
    }
  };

  updateFieldsData = async (data, dataObject) => {
    for (let loop = 0; loop < data.length; loop++) {
      await new Promise((r) => setTimeout(r, 8000));
      if (data[loop].key === "bigColorThumb") {
        this.updateBigColor(data[loop].id, dataObject);
      }
      if (data[loop].key === "energyClassImage") {
        this.updateEnergyClass(data[loop].id, dataObject);
      }
      if (data[loop].key === "eprelRegistrationNumber") {
        this.updateMetaRegistration(data[loop].id, dataObject);
      }
      if (data[loop].key === "energyclassletter") {
        this.updateEnergyClassLetter(data[loop].id, dataObject);
      }
      if (data[loop].key === "EprelDataSheetDE") {
        this.updateDataSheet(data[loop].id, dataObject, "EprelDataSheetDE");
      }
      if (data[loop].key === "EprelDataSheetFR") {
        this.updateDataSheet(data[loop].id, dataObject, "EprelDataSheetFR");
      }
      if (data[loop].key === "EprelDataSheetDE") {
        this.updateDataSheet(data[loop].id, dataObject, "EprelDataSheetDE");
      }
    }
    await data?.forEach((item) => {});

    setTimeout(() => {
      this.setState({
        isLoading: false,
        isFinished: true,
      });
    }, 1800000);
  };

  getModelIndetifierData = async (value, metaFieldsData) => {
    // const validData = await verifyModelIdentifier(value, this.state.language);
    // if (validData) {
    //   this.setState({
    //     modelIdentiferData: validData,
    //   });
    //   const dataObject = validData;
    //   this.updateFieldsData(metaFieldsData, dataObject);
    // }

    return new Promise((resolve, reject) => {
      axios
        .get(
          this.state.modelIdentifierURL +
            value +
            "&countryCode=" +
            this.state.language,
          {
            auth: {
              username: "eprel-iways-user",
              password: "eprel-iways-password123",
            },
          }
        )
        .then((res) => {
          this.setState({
            modelIdentiferData: res.data,
          });
          const dataObject = res?.data;
          this.updateFieldsData(metaFieldsData, dataObject);
          resolve("");
        })
        .catch((err) => {
          reject("");
        });
    });
  };

  updateBigColor = async (id, dataObject) => {
    const data = dataObject;

    let bigColorThumb = {
      metafield: {
        id: id,
        value: data?.data?.bigColorThumb,
        key: "bigColorThumb",
      },
    };
    await axios
      .put(
        this.state.putURL +
          "?metafieldId=" +
          id +
          "&countryCode=" +
          this.state.language,
        bigColorThumb,
        {
          auth: {
            username: "eprel-iways-user",
            password: "eprel-iways-password123",
          },
        }
      )
      .then((res) => {});
  };

  updateEnergyClass = async (id, dataObject) => {
    const data = dataObject;

    let energyClassImage = {
      metafield: {
        id: id,
        value: data?.data?.energyClassImage,
        key: "energyClassImage",
      },
    };

    await axios
      .put(
        this.state.putURL +
          "?metafieldId=" +
          id +
          "&countryCode=" +
          this.state.language,
        energyClassImage,
        {
          auth: {
            username: "eprel-iways-user",
            password: "eprel-iways-password123",
          },
        }
      )
      .then((res) => {});
  };

  updateMetaRegistration = async (id, dataObject) => {
    const data = dataObject;

    // registration number
    let eprelRegistrationNumber = {
      metafield: {
        id: id,
        value: data?.data?.eprelRegistrationNumber,
        key: "eprelRegistrationNumber",
      },
    };
    await axios
      .put(
        this.state.putURL +
          "?metafieldId=" +
          id +
          "&countryCode=" +
          this.state.language,
        eprelRegistrationNumber,
        {
          auth: {
            username: "eprel-iways-user",
            password: "eprel-iways-password123",
          },
        }
      )
      .then((res) => {});
  };

  updateEnergyClassLetter = async (id, dataObject) => {
    const data = dataObject;

    // energyclassletter
    let energyclassletter = {
      metafield: {
        id: id,
        value: data?.data?.energyClass,
        key: "energyclassletter",
      },
    };
    await axios
      .put(
        this.state.putURL +
          "?metafieldId=" +
          id +
          "&countryCode=" +
          this.state.language,
        energyclassletter,
        {
          auth: {
            username: "eprel-iways-user",
            password: "eprel-iways-password123",
          },
        }
      )
      .then((res) => {});
  };

  updateDataSheet = async (id, dataObject, keyID) => {
    const data = dataObject;

    // energyclassletter
    let EprelDataSheetDE = {
      metafield: {
        id: id,
        value: data?.data?.productDataSheet,
        key: keyID,
      },
    };
    await axios
      .put(
        this.state.putURL +
          "?metafieldId=" +
          id +
          "&countryCode=" +
          this.state.language,
        EprelDataSheetDE,
        {
          auth: {
            username: "eprel-iways-user",
            password: "eprel-iways-password123",
          },
        }
      )
      .then((res) => {});
  };

  viewModeHanler = (value) => {
    this.setState({
      isSyncMode: value,
    });
  };

  render() {
    const { isSyncMode, isLoading, isLogin, language } = this.state;

    return isLogin ? (
      <>
        <Header
          setSelectedLanguage={this.setSelectedLanguage}
          language={language}
        />
        <div>
          {isSyncMode ? (
            <div className="synclAllWrapper">
              {isLoading ? (
                <div className="loadingWrapper">
                  {!this.state.response?.length ? (
                    <span>Loading Products</span>
                  ) : (
                    <>
                      <span>
                        Updating {this.state.response?.length} Products...
                      </span>
                      <p>
                        Please wait, it will take a while to update all the
                        products
                      </p>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <h1>Would you like to Sync All</h1>
                  <button onClick={this.syncAllData}>Update All</button>
                  <h1>OR</h1>
                  <button onClick={() => this.viewModeHanler(false)}>
                    Update a Single Product
                  </button>

                  {this.state.isFinished && <p>Data Updated</p>}
                </>
              )}
            </div>
          ) : (
            <SyncById
              viewModeHanler={this.viewModeHanler}
              language={language}
            />
          )}
        </div>{" "}
        <Footer />
      </>
    ) : (
      <Login setIsLogin={this.setIsLogin} />
    );
  }
}
export default IwaysEperlGetApi;
