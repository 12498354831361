import axios from "axios";

class DataService {
  verifyData = (value, language) => {
    const test =
      "https://eprelclient.i-ways-network.org/api/ledvance/product-details?modelIdentifier=" +
      value +
      "&countryCode=" +
      language;
    return new Promise((resolve, reject) => {
      axios
        .get(
          "https://eprelclient.i-ways-network.org/api/ledvance/product-details?modelIdentifier=" +
            value +
            "&countryCode=" +
            language,
          {
            auth: {
              username: "eprel-iways-user",
              password: "eprel-iways-password123",
            },
          }
        )
        .then((response) => {
          resolve(response?.data);
        })
        .catch((err) => {
          reject("");
        });
    });
  };
}

const instance = new DataService();

export default instance;
